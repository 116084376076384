<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      show-date
      :date-validation="false"
      verticle-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:total_distance="{ item }">
        <div>
          {{ formatDecimal(item.total_distance) }}
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <v-autocomplete
            v-model="vehicleIds"
            dense
            :menu-props="{ closeOnContentClick: isBtn }"
            :loading="isVehicleLoading"
            :items="vehicleList"
            item-value="id"
            item-text="name"
            :label="$t('select_vehicle')"
            auto-select-first
            clearable
            multiple
            @click:clear="clearList"
          >
            <!-- selected -->
            <template v-slot:selection="data">
              <span
                v-if="data.index === 0"
                class="selected"
              >
                <span>{{ data.item.name }}</span>
              </span>
              <span
                v-if="data.index === 1"
                class="grey--text text-caption"
              >
                (+{{ vehicleIds.length - 1 }} others)
              </span>
            </template>
            <!-- selected -->

            <!-- for footer -->
            <template v-slot:prepend-item>
              <div class="text-right px-1 autocomplete-footer">
                <v-btn
                  small
                  color="primary"
                  @click="fetchData"
                >
                  Apply
                </v-btn>
              </div>
            </template>
            <!-- for footer -->
          </v-autocomplete>
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <!-- <template #actionModals>
        <div></div>
      </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      //
      isBtn: false,
      vehicleIds: [],

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "vehicle_id",
          width: "15%",
        },

        {
          text: this.$t("vehicle_variant"),
          align: "left",
          sortable: false,
          value: "vehicle_variant_name",
        },
        {
          text: this.$t("distance_travelled"),
          align: "left",
          sortable: false,
          value: "total_distance",
        },

        {
          text: this.$t("total_energy_consumed"),
          align: "left",
          sortable: false,
          value: "total_energy_consumed",
          notShow: "ice",
        },
        {
          text: this.$t("avg_mileage"),
          align: "left",
          sortable: false,
          value: "avg_avg_mileage",
          notShow: "ice",
        },

        {
          text: this.$t("electric_vehicle_cost"),
          align: "left",
          sortable: false,
          value: "total_electricity_per_unit_price",
          notShow: "ice",
        },
        {
          text: this.$t("avg_speed"),
          align: "left",
          sortable: false,
          value: "avg_avg_speed",
        },
        {
          text: this.$t("top_speed"),
          align: "left",
          sortable: false,
          value: "top_speed",
        },
        {
          text: this.$t("total_trip_duration"),
          align: "left",
          sortable: false,
          value: "total_duration",
        },
        {
          text: this.$t("savings"),
          align: "left",
          sortable: false,
          value: "total_savings",
          notShow: "ice",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "comparison/getIsLoading",
      meta: "comparison/getVehicleMeta",
      list: "comparison/getVehicleComparison",

      isVehicleLoading: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
    }),
    _headers() {
      const arr = [];

      this.headers.forEach((el) => {
        if (
          this.authUser.user_nature == "fleet" &&
          this.authUser.user_tech == "ice"
        ) {
          if (el.notShow !== "ice") {
            arr.push(el);
          }
        } else {
          arr.push(el);
        }
      });
      return arr;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const fil = {
      dateType: this.options.dateType,
      dateRange: this.options.date,
      vehicleIds: this.vehicleIds,
    };
    const params = {
      name: "vehicle_comparison",
      filters: fil,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
    // this.clearList();
  },
  async mounted() {
    await this.$store.dispatch("vehicles/list", {
      performance_comparison: true,
    });
    setTimeout(() => {
      if (this.$route?.query?.vehicleIds) {
        let id = this.$route?.query?.vehicleIds;
        if (typeof id === "object") {
          this.vehicleIds = this.$route?.query?.vehicleIds.map((r) =>
            parseInt(r)
          );
        } else {
          this.vehicleIds = [parseInt(id)];
        }
      }
    }, 0);
  },
  methods: {
    async fetchData() {
      if (this.vehicleIds.length > 0) {
        this.isBtn = true;
        const params = {
          vehicle_id: this.vehicleIds,
          date_range: this.options.date,
        };
        await this.$store
          .dispatch("comparison/vehicleComarpisonList", params)
          .finally(() => (this.isBtn = false));
      }
    },

    //

    formatDecimal(v) {
      if (v != "N/A") {
        return parseFloat(v).toFixed(2);
      } else {
        return v;
      }
    },

    //
    async clearList() {
      await this.$store.commit("comparison/SET_VEHICLE_COMPARISON", []);
    },
  },
};
</script>
<style lang="sass" scoped>

:deep .v-autocomplete
  .v-select__selections
    .selected
      width: 70px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>
